.navbar {
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    justify-content: space-between;
    flex-wrap: wrap;
    background: transparent;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0);
    z-index: 999;
    transition: all 0.6s ease;
}

.centered,
.navbar {
    display: flex;
    align-items: center;
}

.navbar .w-100-nav {
    margin: 0;
    width: 100%;
    justify-content: left;
    align-items: center;
    flex-wrap: nowrap;
}

.logged {
    width: max-content;
}

// NAVBAR LOGO
.navbar .breakpoint__xl-only,
.navbar .logo {
    width: max-content;
}

.navbar .logo {
    padding-right: 30px;
}

.navbar .navbar-item {
    position: relative;
    display: inline-block;
    padding: 30px 6px;
    height: max-content;
    cursor: default;
}

.navbar .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
}

.navbar-item {
    pointer-events: auto;
}

.navbar .logo .navbar-item {
    position: relative;
    top: 2px;
    left: 0;
    padding: 0;
    width: max-content;
    border-bottom: none;
}

.navbar a {
    position: relative;
    font-size: 14px;
    font-weight: 800;
    text-decoration: none !important;
    color: #111;
    outline: none;
    transition: all 0.3s ease;
}

.navbar.white .btn,
.navbar.white a {
    color: #111;
}

.navbar .logo .navbar-item a {
    padding: 0;
}

#myHeader.navbar.white .btn,
.navbar.white a,
.navbar.sticky.white a {
    color: #111;
}

.navbar.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: #1b1b1b;
    border-bottom: 1px solid #ddd;
    transition: all 0.6s ease;
}

#myHeader.navbar.sticky.white {
    background: rgb(33, 36, 40);
    border-bottom: 0px;
    box-shadow: rgba(10, 10, 10, 0.8) 0px 4px 20px 0px;
}

// END LOGO

// SEARCH
.navbar .search {
    display: flex;
    width: -webkit-max-content;
    width: max-content;
    padding-left: 30px;
}

#myHeader.navbar .search #quick_search {
    color: rgb(255, 255, 255);
    background: rgba(255, 255, 255, 0.1);
}

.navbar .search #quick_search {
    padding: 4px 11px;
    border: none;
    border-radius: 6px;
    font-size: 15px;
    background: rgba(131, 100, 226, 0.1);
    width: 274px;
    height: 34px;
    outline: none;
}

.navbar .search #quick_search {
    border-radius: 20px;
}

// END SEARCH
// MENU
.navbar .breakpoint__xl-only {
    margin-left: auto;
}

.navbar .menu {
    display: flex;
    justify-content: flex-end;
}

.dropdown-custom.btn {
    font-size: 14px;
    border: 0;
    position: relative;
    top: -1px;
    overflow: unset !important;
    letter-spacing: normal;
    font-weight: 800;
    padding: 0 20px 0 0;
    background: none !important;
    font-family: "DM Sans",Helvetica,Arial,sans-serif;
    text-transform: inherit;
}

.dropdown-custom.btn:before {
    content: "";
    position: absolute;
    top: -10px;
    left: 19px;
    width: max-content;
    height: auto;
    padding: 30px 40px;
    background: 0;
}

// .dropdown-toggle:after {
//     display: inline-block;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: "";
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
// }

// .dropdown-toggle::after {
//     font-family: FontAwesome;
//     content: "";
//     color: rgba(0, 0, 0, 0.5);
//     padding-left: 2px;
//     font-size: 8px;
//     position: absolute;
//     top: 6px;
//     right: 5px;
//     border-width: initial;
//     border-style: none;
//     border-color: initial;
//     border-image: initial;
// }

.dropdown-custom.btn:after {
    margin-left: 5px !important;
}

.no-dropdown {
    &::after {
        content: none;
    }
}

.dropdown-toggle {
    white-space: nowrap;
}

.breakpoint__xl-only .dropdown-toggle:hover {
    .item-dropdown {
        display: block;
    }
}

#myHeader .dropdown-toggle::after {
    color: rgb(255, 255, 255);
}

.btn,
.btn span {
    position: relative;
    color: #fff;
}

.btn span {
    z-index: 1;
}

.navbar.white .btn .lines {
    top: 24px;
}

.navbar .navbar-item .lines {
    position: absolute;
    top: 22px;
    display: block;
    width: 0;
    border-bottom: 2px solid #8364e2;
    transition: 0.7s;
}

.navbar .navbar-item .lines {
    border-bottom: 2px solid rgb(255, 52, 63);
}

.navbar .navbar-item:hover .lines {
    width: 90%;
    transition: 0.7s;
}

.item-dropdown {
    width: max-content;
    position: absolute;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    inset: 50px auto auto 0;
    padding: 0;
    animation: smoothDrop 0.2s ease;
    box-shadow: 2px 2px 30px 0 rgba(20, 20, 20, 0.1);
    z-index: 1;
    display: none;
}

.item-dropdown {
    background: rgba(33, 36, 40, 0.9);
    box-shadow: rgba(20, 20, 20, 0.1) 2px 2px 30px 0px;
    color: rgb(255, 255, 255) !important;
}

.item-dropdown .dropdown {
    position: relative;
    text-align: center;
}

.item-dropdown .dropdown a {
    color: #333 !important;
    text-transform: none;
    font-weight: 400;
    letter-spacing: normal;
    display: block;
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.1);
    padding: 8px 20px;
    min-width: 210px;
    width: 100%;
    text-align: left;
}

.item-dropdown .dropdown a {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    color: rgb(255, 255, 255) !important;
}

.item-dropdown .dropdown a:hover {
    background: rgb(255, 52, 63);
    color: rgb(255, 255, 255) !important;
}

// END MENU
// GROUP BUTTON CONTROLS
.mainside {
    width: max-content;
    padding-left: 0;
    padding-right: 0;
}

.mainside .connect-wal {
    display: block;
}

.mainside a {
    display: inline;
    text-align: center;
    color: #fff !important;
    background: rgb(255, 52, 63);
    border-radius: 6px;
    letter-spacing: normal;
    outline: 0;
    font-weight: 800;
    text-decoration: none;
    padding: 6px 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 20px 0 rgba(131, 100, 226, 0);
    transition: all 0.3s ease;
}

.navbar .mainside a:hover {
    box-shadow: rgb(255, 52, 63) 0 2px 20px 0px;
    transition: all 0.3s ease 0s;
}

.logout {
    display: flex;
    align-items: center;
}

.navbar .mainside a {
    text-align: center;
    background: rgb(255, 52, 63);
    border-radius: 30px;
    color: rgb(255, 255, 255) !important;
}

.mainside .de-menu-notification,
.mainside .logout a {
    display: inline-block;
    margin: 0 5px;
}

.mainside .de-menu-notification {
    position: relative;
    cursor: pointer;
    width: 38px;
    height: 38px;
    background: #eee;
    text-align: center;
    line-height: 32px;
    border-radius: 30px;
    padding-top: 3px;
}

.mainside .de-menu-notification .d-count {
    pointer-events: none;
    display: inline-block;
    position: absolute;
    z-index: 100;
    background: rgb(255, 52, 63);
    line-height: 1em;
    padding: 6px;
    width: 22px;
    border-radius: 30px;
    top: -10px;
    right: -10px;
    color: #fff;
    font-weight: 700;
    font-size: 10px;
}

.menu .notification {
    > span:first-child {
        position: relative;
    }
}

.fa.fa-bell {
    pointer-events: none;
}

.mainside .de-menu-profile {
    position: relative;
    cursor: pointer;
}

.mainside .de-menu-profile img {
    pointer-events: none;
    width: 38px;
    border-radius: 30px;
    -moz-border-radius: 30px;
    -webkit-border-radius: 30px;
    margin-left: 5px;
    margin-bottom: 2px;
}

.de-flex {
    display: flex;
    justify-content: space-between;
}

.mainside .de-menu-notification .popshow h4 {
    font-weight: 700;
    display: block;
    padding: 0;
    font-size: 16px;
    margin: 5px 0 0;
}

.mainside .de-menu-notification .popshow span.viewaall,
.mainside .de-menu-profile .popshow .d-name span.name {
    color: rgb(255, 52, 63);
}

.mainside .de-menu-notification .popshow ul li .mainnot {
    margin-bottom: 5px;
}

.mainside .de-menu-notification .popshow ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
    text-align: left;
}

.mainside .de-menu-notification .popshow ul li {
    line-height: 20px;
}

.mainside .de-menu-notification .popshow ul li .mainnot {
    color: #0d0c22;
}

.mainside .de-menu-notification .popshow ul li .mainnot img {
    width: 32px;
    height: 32px;
    position: absolute;
    border-radius: 30px;
}

.mainside .de-menu-notification .popshow ul li .mainnot .d-desc {
    position: relative;
    top: 0;
    margin-left: 45px;
}

.mainside .de-menu-notification .popshow ul li .mainnot .d-desc .d-time {
    display: block;
    font-size: 14px;
    color: #727272;
}

.mainside .de-menu-notification .popshow {
    cursor: default;
    width: 250px;
    position: absolute;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    inset: 50px auto auto -200px;
    padding: 20px;
    animation: smoothDrop 0.2s ease;
    -webkit-animation: smoothDrop 0.2s ease;
    box-shadow: 2px 2px 30px 0 rgba(20, 20, 20, 0.1);
    z-index: 1;
}

.mainside .de-menu-profile .popshow {
    cursor: default;
    width: 250px;
    position: absolute;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    inset: 50px auto auto -200px;
    padding: 20px;
    animation: smoothDrop 0.2s ease;
    -webkit-animation: smoothDrop 0.2s ease;
    box-shadow: 2px 2px 30px 0 rgba(20, 20, 20, 0.1);
    z-index: 1;
}

.mainside .de-menu-profile .popshow .d-name {
    margin-bottom: 15px;
}

.mainside .de-menu-profile .popshow .d-name h4,
.mainside .de-menu-profile .popshow .d-wallet h4,
.mainside .de-menu-profile .popshow .d-balance h4 {
    font-weight: 700;
    display: block;
    padding: 0;
    font-size: 16px;
    margin: 5px 0 0;
}

.mainside .de-menu-profile .popshow .d-wallet #btn_copy {
    margin-left: 15px;
    border-radius: 4px;

    &:hover {
        background: rgb(255, 52, 63);
        color: #fff;
    }
}

.d-flex {
    display: flex;
    align-items: center;
}

.mainside .de-menu-profile .popshow .d-wallet .d-wallet-address {
    display: inline-block;
    max-width: 64%;
    overflow: hidden;
    height: 21px;
}

.mainside .de-menu-profile .popshow .d-balance {
    font-size: 14px;
    margin-bottom: 15px;
}

.mainside .de-menu-profile .popshow .d-wallet {
    font-size: 14px;
    margin-bottom: 15px;
}

.mainside .de-menu-profile .popshow .d-line {
    margin: 15px 0;
    border-top: 1px solid #bbb;
}

.mainside .de-menu-profile .popshow .de-submenu-profile {
    list-style: none;
    margin: 0;
    padding: 0;
}

.mainside .de-menu-profile .popshow .de-submenu-profile li {
    padding: 5px 0;
}

.mainside .de-menu-profile .popshow .de-submenu-profile li span {
    cursor: pointer;
    color: #0d0c22 !important;
    background: none;
    padding: 0;
    font-weight: 700;
    font-size: 14px;
    display: block;
    text-align: left;
}

.mainside .de-menu-profile .popshow .de-submenu-profile li span i {
    padding: 7px 9px;
    background: #eee;
    border-radius: 30px;
    width: 28px;
    margin-right: 5px;
}

// ICON MENU MOBILE
.breakpoint__l-down {
    display: none;
}

.navbar .nav-icon {
    display: none;
}

.navbar .menu-line,
.navbar .menu-line1,
.navbar .menu-line2 {
    background: rgb(255, 255, 255);
}

@media only screen and (max-width: 1199px) {
    .navbar {
        background: rgb(33, 36, 40);
        top: 0;
    }

    .navbar.navbar {
        position: relative;
    }

    #myHeader .container {
        max-width: unset !important;
    }

    .navbar .w-100-nav {
        display: block;
        padding: 20px 2%;
    }

    .navbar .nav-icon {
        position: absolute;
        top: 35px;
        right: 25px;
        display: block;
        width: 20px;
        height: 10px;
        line-height: 0;
        cursor: pointer;
        background: none;
        border: none;
        padding: 0;
        z-index: 9999;
    }

    .navbar.navbar .logo {
        position: relative;
        left: -10px;
        display: inline-block;
    }

    .navbar.navbar .search {
        display: inline-block;
        position: relative;
        top: -9px;
    }

    .navbar.navbar .search #quick_search {
        width: 100%;
    }

    .navbar.navbar .mainside {
        position: absolute;
        right: 60px;
        top: 28px;
    }

    .navbar .menu {
        display: block;
        padding: 15px 0 10px;
    }

    .navbar .navbar-item {
        display: block;
        padding: 15px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .lines {
            display: none;
        }
    }

    .navbar.navbar .dropdown-custom.btn {
        color: #bbb;
        width: 100%;
        text-align: left;
    }

    .navbar.navbar .dropdown-custom.btn:after {
        float: right;
        font-size: 0.9rem;
    }

    .item-dropdown {
        position: relative;
        width: 100%;
        inset: unset;
        padding-top: 10px;
        border: 0;
        background: rgba(33, 36, 40, 0.9);
        box-shadow: rgba(20, 20, 20, 0.1) 2px 2px 30px 0px;
        color: rgb(255, 255, 255) !important;
    }

    .item-dropdown .dropdown a {
        padding: 10px 10px 10px 0;
        width: 100%;
        text-align: left;
        left: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .breakpoint__xl-only {
        display: none;
    }

    .breakpoint__l-down {
        display: block;

        .item-dropdown {
            display: block;
        }
    }

    .navbar .menu-line,
    .navbar .menu-line1 {
        position: absolute;
        width: 100%;
        height: 2px;
    }

    .navbar .menu-line {
        top: 0;
    }

    .navbar .menu-line1 {
        top: 5px;
    }

    .navbar .menu-line2 {
        position: absolute;
        width: 100%;
        height: 2px;
        top: 10px;
    }
}

#back-to-top {
    background: #ff343f;
    position: fixed;
    bottom: -40px;
    right: 10px;
    z-index: 1020;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    border: 0;
    border-radius: 100%;
    -moz-border-radius: 100%;
    -webkit-border-radius: 100%;
    text-decoration: none;
    outline: none;
    transition: opacity .7s ease;
    animation-duration: 900ms;
    animation-timing-function: ease;
    animation-delay: 0;
    animation-name: fadeOutDown;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-iteration-count: 1;

    &::before {
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        font-size: 18px;
        content: "\f106";
        color: #fff;
        position: relative;
    }

    &.hideButton {
        opacity: 0;
        bottom: 0px;
    }

    &.showButton {
        bottom: 20px;
        opacity: 1;
        animation-duration: 900ms;
        animation-timing-function: ease;
        animation-delay: 300ms;
        animation-name: fadeInUp;
        animation-direction: normal;
        animation-fill-mode: both;
        animation-iteration-count: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}


@keyframes fadeOutDown {
    from {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
    
    to {
        opacity: 0;
        transform: translate3d(0, 100%, 0);
    }
}

@media only screen and (max-width: 768px) {
    .navbar.navbar .search {
        display: none;
    }
}