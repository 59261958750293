.container {
    padding-left: 30px;
    padding-right: 30px;
}
footer.footer-light #form_subscribe input[type="text"] {
    width: 80%;
    float: left;
    border-radius: 5px 0 0 5px;
    background: hsla(0, 0%, 100%, 0.7);
    border: 1px solid #333;
    border-right: none;
    padding: 6px 12px;
    font-size: 1rem;
    line-height: 1.5;
    box-shadow: 2px 2px 20px 0 rgba(20, 20, 20, 0.05);
    &:focus {
        outline: none;
    }
}
footer.footer-light #form_subscribe #btn-subscribe i {
    text-align: center;
    font-size: 28px;
    float: left;
    width: 20%;
    background: #8364e2;
    color: #fff;
    display: table-cell;
    padding: 5px 0;
    border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    cursor: pointer;
}
footer.footer-light .subfooter {
    margin-top: 40px;
    padding: 20px 0;
    border-top: 1px solid #eee;
}
footer.footer-light .subfooter span {
    cursor: pointer; 
}
footer.footer-light .subfooter span img {
    margin-right: 30px;
}
footer.footer-light .subfooter span img.f-logo.d-1 {
    display: inline-block;
    width: 100px;
}
footer.footer-light .subfooter span img.f-logo.d-3,
footer.footer-light .subfooter span img.f-logo.d-4 {
    display: none;
}
footer.footer-light .subfooter span.copy {
    cursor: default;
    margin: 0;
}

footer .widget {
    margin-bottom: 30px;
    padding-bottom: 30px;
}
footer .widget ul {
    list-style: none;
    margin: 0;
    padding: 0;
}
footer .widget ul li {
    margin: 5px 0;
}
footer .widget h5 {
    margin-bottom: 20px;
    margin-top: 0;
    font-weight: 700;
    color: #fff;
    font-size: 18px;
}
footer {
    background: #212428;
}
.wrap {
    padding: 80px 0 0;
    background: hsla(0, 0%, 100%, 0.025) !important;
}
footer.footer-light {
    border-top: 1px solid hsla(0, 0%, 100%, 0.025);
    color: #a2a2a2;
}
footer.footer-light a {
    color: #a2a2a2;
    font-weight: 400;
    text-decoration: none !important;
}
footer.footer-light a:hover {
    color: #ff343f;
}
footer.footer-light #form_subscribe input[type="text"] {
    width: 80%;
    float: left;
    border-radius: 30px 0 0 30px;
    color: #fff;
    background: hsla(0, 0%, 100%, 0.1);
    border: 1px solid #333;
    border-right: none;
    padding: 6px 12px;
    margin-bottom: 0;
    box-shadow: 2px 2px 20px 0 rgba(20, 20, 20, 0.05);
}
footer.footer-light #form_subscribe #btn-subscribe i {
    text-align: center;
    font-size: 28px;
    float: left;
    width: 20%;
    background: #ff343f;
    color: #fff;
    display: table-cell;
    padding: 5px 0;
    border-radius: 0 30px 30px 0;
    font-family: "ElegantIcons";
}
footer.footer-light .subfooter {
    margin-top: 40px;
    padding: 20px 0;
    border-top: 1px solid hsla(0, 0%, 100%, 0.1);
}
footer.footer-light .subfooter span {
    cursor: pointer;
}
footer.footer-light .subfooter span img {
    margin-right: 30px;
}
footer.footer-light .subfooter span img.f-logo.d-1 {
    display: inline-block;
}
footer.footer-light .subfooter span img.f-logo.d-3,
footer.footer-light .subfooter span img.f-logo.d-4 {
    display: none;
}
footer.footer-light .subfooter span.copy {
    cursor: default;
    margin: 0;
}
footer.footer-light .subfooter .social-icons {
    display: inline-block;
}
footer.footer-light .subfooter .social-icons span {
    color: #595d69;
}
footer.footer-light .subfooter .social-icons span i {
    text-shadow: none;
    color: #fff;
    background: #212428;
    padding: 12px 10px 8px;
    width: 34px;
    height: 34px;
    text-align: center;
    font-size: 16px;
    border-radius: 5px;
    margin: 0 5px;
    transition: 0.3s;
    line-height: 0.75em;
    vertical-align: -15%;
}
footer.footer-light .subfooter .social-icons span:hover i {
    color: #111;
    background: #fff;
    transition: 0.3s;
}
@media screen and (max-width: 1024px) {
    .subfooter .de-flex {
        display: block;
    }
}
@media screen and (max-width: 768px) {
    footer.footer-light .subfooter span.copy {
        display: block;
    }
}
